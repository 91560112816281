.panel-details-card-style {
  margin-bottom: 20px;

  .card-block {
    display: flex;
  }
}

.panel-details-card-text {
  line-height: 1.6em;
  margin-bottom: 10px;

  ul {
    list-style-type: none;
  }
}
  
.panel-details-card-preview {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 40%;
  margin-right: 20px;
  height: 200px;
  width: 250px;
  background-color: #f8f8f8;
}
  
.panel-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
  
div.panel-details-item p {
  margin-bottom: 10px;
}

.template-preview {
  border: 1px solid rgba(255,255,255,0);
  height: 100%;
  width: 100%;
  object-fit: contain;
}
  
.template-preview:hover {
  border: 1px dotted #0092b5;
}