div.template-tokens-input {
    div.input-group {
        max-width: 100%;
        table-layout: fixed;
    }
}

#errorText {
    display: block;
    margin-top: -10px;
    margin-bottom: 15px;
}