.color-input-container {
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-right: 100px;
}

.color-preview {
  width: 30px;
  height: 30px;
  margin-left: 5px;
  border: 1px solid black;
}

.color-input {
  width: 150px;
}

.color-input input {
  font-size: 12px !important;
}

.color-input label {
  font-size: 12px !important;
}

.color-input label .MuiFormLabel-asterisk {
  color: red !important;
}