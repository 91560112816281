.resize-guide {
    text-align: right;
    justify-content: center;
}

.resize-guide .info {
    font-size: 16px;
    margin-right: 5px;
}

.errors-info {
    margin-top: 5px;
}

.errors-info p {
    font-weight: bold;
}

.errors-info .error {
    display: inline-flex;
    margin-left: 10px;
    margin-top: 5px;
}

.errors-counter {
    margin-top: 10px;
}

.error-counter-row p {
    display: inline;
    font-weight: bold;
}
