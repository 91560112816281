.ensemble-prominent {
    font-weight: bold;
}

.ensemble-deleted {
    color: red
}

.ensemble-line-padded {
    padding: 20px
}

.no-link-text {
    color: #333943;
    font-size: .10m;
  
    &:focus, &:hover, &:visited, &:link, &:active {
      text-decoration: none;
    }
}
  
.text-top-padding {
    padding-top: 10px
}