h2.result-message {
    text-align: center;
    font-weight: bold;
}
h4.result-message {
    text-align: center;
    font-weight: lighter;
}

.results-grid {
    align-items: center;
    justify-content: center;
    text-align: center;
}