#indicator {
    display: inline-block;
    vertical-align: middle;
}

#spinner {
    display: inline-block;
    vertical-align: middle;
}

.download-button {
    display: inline-block;
    vertical-align: middle;
}