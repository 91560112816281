.replace-page {
    small {
        font-style: italic;
    }

    .input-area {
        margin-bottom: 20px;
    }

    .token-input textarea {
        min-height: 200px;
    }

    .instructions {
        text-align: center;
    }

    .template-preview {
        width: 100%;
        margin: auto;
        display: inline-block;
    }

    .landscape .template-preview {
        height: 125px;
    }

    .portrait .template-preview {
        height: 200px;
    }

    p, .card-block p {
        margin-bottom: 5px;
    }

    .panel-type {
        text-align: center;
    }

    .draggable .card:hover {
        box-shadow: 0 3px 6px 0 rgb(0 0 0 / 40%);
    }

    .prominent-label {
        font-weight: bold;
    }

    .export-button {
        width: fit-content;
        margin: auto
    }

    .accordion {
        border: 1px solid #999;

        .accordion-header {
            > div {
                display: inline;
            }
            
            .preview-label, .status-label {
                text-align: right;
            }

            .template-preview {
                height: 50px;        
            }
        }

        .accordion-body {
            padding: 0px 40px 40px;
        }

        .header-content {
            width: 95%;
            display: inline-flex;
        }

        .approval-checkbox {
            text-align: center;
        }

        .accordion-buttons {
            width: fit-content;
            margin: auto
        }
    }
}




