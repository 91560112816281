// These classes are shared between the Resize Tool and Component Composition

.ensemble-preview {
  display: flex;
  justify-content: space-around;
  margin: 10px 0 10px 0;

  .ensemble-panel-tile {
    margin: 0 1px 0 1px;
    display: flex;
    align-items: flex-start;
    min-height: 150px;
  }

  .ensemble-panel-title {
    display: table;
    margin: 0 auto;
    margin-bottom: 8px;
  }
  .ensemble-panel-preview {
    max-width: 220px;
    max-height: 220px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.2);
    border: 1px solid lightgray;
  
    &:hover {
      box-shadow: 0 0 6px rgba(49, 158, 186, 0.486);
    }
  }
  .ensemble-panel-size {
    display: table;
    margin: 0 auto;
    margin-bottom: 8px;
  }
}

.ensemble-panel-preview-direction {
  flex-direction: column;
}
