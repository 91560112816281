// Despite the class naming convention, both the Resize Tool and Component Composition use these classes.

.composed-previews-overview-section {
    display: flex;
    flex-direction: column;

    .composed-previews-overview-container {
        display: flex;
        flex-wrap: wrap;
    }
}

.composed-previews-section{
    display: flex;      
    .composed-previews-section-title {
        margin-bottom: 0;
        font-weight: 400;
        color: #0088a9;
    }
    
    .composed-previews-section-buttons-group{
        display: flex;
        column-gap: 5px;
        margin-left: auto;
        margin-top: 18px;
        align-items: center;

        .MuiSlider-root {
            color: #1976D2;
            height: 3px;
            margin-right: 20px;
            .MuiSlider-valueLabel {
                display: block !important;
                font-size: 12px;
            }
        }
    }
}

