.button-bar {
  border-radius: 50px !important;
  background-color: #dfdfdf;
  display: flex;
  justify-content: space-around;
  width: 200px;
  padding: 5px;
}

.switch-button {
  border-radius: 50px !important;
  height: 50px;
  border: none !important;
  width: 45% !important;
  color: black !important;
}

.switch-button.active {
  background-color: #ffffff !important;
  border: 1px solid black !important;
}

.switch-button:hover {
  opacity: .6;
}

.button-bar-container {
  display: flex;
  flex-direction: column;
  margin-right: 100px;
}