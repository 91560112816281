.batch-preview-panel-card-style {
  margin-bottom: 20px;

  width: 50%;

  .card-block {
    display: flex;
  }
}

.ensemble-prominent {
  font-weight: bold;
}

.ensemble-details {
  margin-top: 10px;
}

