// Both the Resize Tool and Component Composition use these classes.

.job-details-header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 8px;

    h3 {
        margin: 8px 20px 8px 0;
    }    
}

.job-details-subheader {
    color: grey;
}

.approve-buttons-wrapper {
    display: flex;
    align-items: baseline;

    .publish-button {
        margin-right: 8px;
    }
}
