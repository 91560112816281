// Despite the class name convention, these classes are used by both Resize and Component Composition.

.ensemble-preview-body {
    display: flex;
    flex-direction: column;
    flex: 1;

    .ensemble-preview-body-panels {
        display: flex;
        justify-content: space-around;
        margin: 10px 0 10px 0;
    }

    .warning-container {
        width: 100%;
    }
}

.resize-preview-tile-container {
    .composable-preview-checkbox {
        display: flex !important;
        align-self: flex-end;
    }
    .ensemble-preview-body-panels {
        display: flex;
        margin: 10px 0 10px 0;
        width: 50%;
        flex-direction: column;
        align-items: flex-start;
    }
    .ensemble-preview-body {
        margin-top: -35px;
    }
}

.resize-preview-tile-container {
    width: 100%;
    margin: 18px 18px 0 0;
    border: #e4e4e4 solid 1px;
    background-color: white;

    .card-block {
        display: flex;
        flex-direction: column;
    }
}

.composable-preview-tile-container {
    width: fit-content;
    max-width: 450px;
    margin: 18px 18px 0 0;
    border: #e4e4e4 solid 1px;
    background-color: white;

    .card-block {
        display: flex;
        flex-direction: column;
    }
}

.composable-preview-tile-header {
    display: flex;
    flex-direction: row;
    align-items: flex-start;

    label {
        font-weight: normal;
        cursor: pointer;
    }

    .composable-preview-checkbox {
        margin: 5px 0 !important;
    }
}

.published-composable-preview-button {
    width: 100%;
    margin-top: 10px;
}

.icon-background {
    color: #FFBF00	;
}

.copy-button-text{
    text-align: left;
}

