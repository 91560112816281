// These classes are shared between Resize Tool and Component Composition

.ensemble-metadata-parent {
    display: flex;
    flex-direction: column;

    .ensemble-metadata {
        display: flex;

        li {
            display: flex;
        }

        ul {
            padding-left: 0px;
            margin-right: 15px;
        }

        .vertical {
            display: inline-block;
            border-left: 1px solid #C0C0C0;
            margin: 0 5px 0 5px;
        }     

        .compare-button {
            width: 100%;
        }
    }

    .smallFont {
        font-size: 12px;
        overflow-wrap: anywhere;
    }
 
}

.template-token-button-group {
    display: flex;
    flex-direction: column;
    padding-top: 5px;
    .template-token-button {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: auto;
    }
}

.additional-info-property {
    margin-right: 5px;
    white-space: nowrap;
    font-weight: bold;
    font-variant: small-caps;
    color: gray;
    font-size: small;
}