.input-form {
    display: flex;
    align-items:ceil($number: 0);
    max-height: 350px;
}

#ids-input {
    display: flex;
    width: 400%;
}

.width-input {
    width: 150px;
    height: 50px;
    margin-top: 18px;
}

.search-button {
    width: 150px;
    height: 48px;  
    margin-left: 40px;
}