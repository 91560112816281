.row {
    display: flex;
    align-items: center;
    justify-content: center;
}

.column {
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 10px;
}

.panel-title {
    display: flex;
    margin: 0 auto;
    margin-bottom: 8px;
    font-weight: bold;    
    justify-content: center;
}