h2.page-title {
    text-align: center;
}

.previews-generation-wrapper {
    margin: 100px 0 150px 0;
    text-align: center;
}

.generate-previews-button {
    margin-top: 12px;
}
