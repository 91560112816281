div.accordion.accordion-job-input {
    margin-top: 50px;

    div.accordion-header {
        padding-left: 0;
    }

    div.accordion-body {
        padding: 0 16px 16px 16px;
    }
}
