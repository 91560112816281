div.upload-zone {
  min-height: 250px;
  display: flex;
  background-color: #f0f0f0;
  border: 1px dashed #979797;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.error {
    border: 1px solid #D24A35;
  }

  .selected-files-for-upload {
    display: flex;

    div {
      background-color: white;
      border: 1px solid gray;
    }
  }
}

div.upload-zone-smaller {
  min-height: auto;
}

.background-text {
  text-align: center;
  color: #ccc;
}

.upload-button-style {
  border-style: none;
  background-color: #0088a9;
  padding: 10px;
  min-width: 120px;
  border-radius: 3px;
}

.upload-dropzone-label {
  color: #fff;
  cursor: pointer;
  margin: 0px;
}