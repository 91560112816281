.ensemble-details-card-style {
    margin-bottom: 20px;
}

div.ensemble-details-item p {
  margin-bottom: 10px;
}

.ensemble-deleted {
  color: red
}