button.generate-previews-button {
    margin-top: 12px;
    margin-right: 0;
    align-self: flex-end;
    background-color: #1976D2 !important;
    float: right;
}

button.generate-previews-button:disabled {
    background-color: rgba(58,65,76,.25) !important;
}

.resize-selector-input {
    display: flex;
}

.divider {
    width: 1px;
    height: 100vh;
    background-color: #dfdfdf;
}
.title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
}
.input-container {
    display: flex;
    margin-bottom: 35px;
}

.designUseCaseId-input {
    width: 250px;
}

.designUseCaseId-input legend {
    font-size: 0.9em !important;
}

.designUseCaseId-input input {
    font-size: 12px !important;
}

.designUseCaseId-input label .MuiFormLabel-asterisk {
    color: red !important;
}

.designUseCaseId-input label {
    font-size: 12px;
}

.prd-input {
    width: 100%;
    margin-bottom: 20px !important;
}

.delete-button {
    width: 18px !important;
    height: 18px !important;
}

.accordion-container {
    height: 30px;
    .MuiAccordionSummary-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
}

.add-more {
    width: 60px;
    height: 60px;
    border-radius: 50% !important;
    background-color: #1976D2 !important;
    border: none !important;
    color: #ffffff !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    outline: none !important;
    margin-top: 10px !important;
}

.add-more:hover {
    opacity: 0.6;
}

.label {
    margin-left: 10px;
    text-transform: uppercase;
    background-color: #41b5dc;
}

.versionDropdown .dropdown-toggle {
    width: 200px;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.versionDropdown {
    width: 200px;
    float: right;
    margin-right: 15px;
    margin-top: -25px;
}

.versionDropdown .dropdown-menu {
    width: 200px;
    margin-left: 15px;
    height: 250px;
    overflow: scroll;
}

.version-option {
    width: 100%;
    height: 40px;
    font-size: 14px !important;
    color: #0088a9 !important;
}